import React, { Component } from 'react'
import ReactDisqusComments from 'react-disqus-comments'
import urljoin from 'url-join'
import config from '../../../data/SiteConfig'

export class Disqus extends Component {
  constructor(props) {
    super(props)
    this.state = {
      toasts: [],
    }
  }

  onSnackbarDismiss = () => {
    const [, ...toasts] = this.state.toasts
    this.setState({ toasts })
  }

  notifyAboutComment = () => {
    const toasts = this.state.toasts.slice()
    toasts.push({ text: 'New comment available!' })
    this.setState({ toasts })
  }

  render() {
    const { postNode, slug } = this.props

    if (!config.disqusShortname) {
      return null
    }

    const post = postNode.frontmatter
    const url = urljoin(config.siteUrl, slug)

    return (
      <ReactDisqusComments
        shortname={config.disqusShortname}
        identifier={post.title}
        title={post.title}
        url={url}
        category_id={post.category_id}
        onNewComment={this.notifyAboutComment}
      />
    )
  }
}
